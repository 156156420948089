<template>
    <div :id="`lmt_${matchId}`" class="lmt">
        <div class="lmt-wrapper">
            <div v-if="hasScoreboard" class="lmt-scoreboard" :class="{'small':isMobile}">
                <div class="lmt-scoreboard-home">{{ match.teams.home.name }}</div>
                <div class="lmt-scoreboard-score-home">{{ match.result.home }}</div>
                <div></div>
                <div class="lmt-scoreboard-score-away">{{ match.result.away }}</div>
                <div class="lmt-scoreboard-away">{{ match.teams.away.name }}</div>
            </div>
            <div class="lmt-container">
                <!--match time-->
                <div v-if="live && selectedEvent" class="match-time-container">
                    <span class="match-time" :class="{'small':isMobile}">{{ matchTime }}'</span>
                </div>
                <!--image-->
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 400 180">
                    <g id="pitch" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                        <path fill="#222" d="M0 0h400v180h-400z"></path>
                        <rect id="pitch-BG" fill="#35713D" x="0" y="0" width="400" height="180"></rect>
                        <rect id="18-yard-away" stroke="#3D8246" stroke-width="2" x="348" y="44" width="51"
                              height="93"></rect>
                        <rect id="18-yard-home" stroke="#3D8246" stroke-width="2" x="1" y="44" width="51"
                              height="93"></rect>
                        <path d="M199,3 L199,178 L199,179 L201,179 L201,178 L201,3 L201,2 L199,2 L199,3 L199,3 Z"
                              id="Shape"
                              fill="#3D8246"></path>
                        <path
                            d="M200,118 L200,118 C215.463973,118 228,105.463973 228,90 C228,74.536027 215.463973,62 200,62 C184.536027,62 172,74.536027 172,90 C172,105.463973 184.536027,118 200,118 L200,118 Z M200,120 L200,120 C183.431458,120 170,106.568542 170,90 C170,73.4314575 183.431458,60 200,60 C216.568542,60 230,73.4314575 230,90 C230,106.568542 216.568542,120 200,120 L200,120 Z"
                            id="Shape-Copy" fill="#3D8246"></path>
                        <circle id="Oval-344" fill="#3D8246" cx="200" cy="90" r="6"></circle>
                        <path
                            d="M74,63 C65.5522257,68.9666814 60,79.0570309 60,90.5 C60,101.942969 65.5522257,112.033319 74,118"
                            id="d-left" stroke="#3D8246" stroke-width="2"
                            transform="translate(67.000000, 90.500000) scale(-1, 1) translate(-59.000000, -90.500000) "></path>
                        <circle id="Oval-70" fill="#3D8246" cx="34" cy="90" r="3"></circle>
                        <path
                            d="M1,11 C6.5228475,11 11,6.5228475 11,1 L9.046875,1 C9.046875,5.44416635 5.44416635,9.046875 1,9.046875 L1,11 L1,11 Z"
                            id="Shape" fill="#3D8246"></path>
                        <path
                            d="M1,171 C5.418278,171 9,174.581722 9,179 L11,179 C11,173.477153 6.5228475,169 1,169 L1,171 L1,171 Z"
                            id="Shape" fill="#3D8246"></path>
                        <path
                            d="M337.884236,62 C328.64831,68.2472191 323,78.6467666 323,90 C323,101.353233 328.64831,111.752781 337.884236,118 L339,116.354082 C330.305144,110.472845 324.989964,100.686649 324.989964,90 C324.989964,79.3133507 330.305144,69.5271551 339,63.645918 L337.884236,62 L337.884236,62 Z"
                            transform="translate(9,0)" id="Shape-Copy-2" fill="#3D8246"></path>
                        <rect id="6-yard-home" stroke="#3D8246" stroke-width="2" x="1" y="69" width="16"
                              height="41"></rect>
                        <path d="M399,69 L383,69 L383,110 L399,110 L399,69 L399,69 Z" id="6-yard-away" stroke="#3D8246"
                              stroke-width="2"></path>
                        <circle id="Oval-70" fill="#3D8246" cx="365" cy="90" r="3"></circle>
                        <path
                            d="M399,9.046875 C394.555834,9.046875 390.953125,5.44416635 390.953125,1 L389,1 C389,6.5228475 393.477153,11 399,11 L399,9.046875 L399,9.046875 Z"
                            id="Shape" fill="#3D8246"></path>
                        <path
                            d="M399,169 C393.477153,169 389,173.477153 389,179 L391,179 C391,174.581722 394.581722,171 399,171 L399,169 L399,169 Z"
                            id="Shape" fill="#3D8246"></path>
                        <rect stroke="#3D8246" stroke-width="2" x="1" y="1" width="398" height="178"></rect>
                    </g>
                    <circle v-if="ballPosition.show" :cx="ballPosition.x"
                            :cy="ballPosition.y" r="0.2rem" stroke="yellow" stroke-width="2" fill="orange"/>
                    <path class="blink-animation" v-if="segmentPosition" :d="segmentPosition"/>
                </svg>
                <div class="logo-container">
                    <img id="lmt_logo" class="lmt-logo" src="@/assets/logo3x.png" alt="FutbolVerileri"/>
                </div>
                <!--events -- if live-->
                <template v-if="live && selectedEvent">
                    <!--home match situation-->
                    <div v-if="selectedEvent.team === 'home'" class="lmt-match-situation-home" :class="matchSituation">
                        <div class="lmt-match-situation-container">
                            <div
                                class="lmt-match-situation-text-box-home"
                            >
                                <h2 class="match-situation-team-name" :class="{'small':isMobile}">{{
                                      match.teams[selectedEvent.team].abbr
                                    }}</h2>
                                <p class="match-situation-position" :class="{'small':isMobile}">{{
                                        MATCH_SITUATION[selectedEvent.situation]
                                    }}</p>
                            </div>
                        </div>
                    </div>
                    <!--away match situation-->
                    <div v-if="selectedEvent.team === 'away'" class="lmt-match-situation-away" :class="matchSituation">
                        <div class="lmt-match-situation-container">
                            <div
                                class="lmt-match-situation-text-box-away"
                            >
                                <h2 class="match-situation-team-name" :class="{'small':isMobile}">
                                    {{ match.teams[selectedEvent.team].abbr }}
                                </h2>
                                <p class="match-situation-position" :class="{'small':isMobile}">
                                    {{ MATCH_SITUATION[selectedEvent.situation] }}
                                </p>
                            </div>
                        </div>
                    </div>
                    <!--special events-->
                    <div v-if="selectedEvent.type === 'goal'" class="center-text-container">
                        <div class="center-text">
                            <v-img src="@/assets/lmt/score.svg" class="match-situation-icon" contain/>
                            <h2 class="match-situation-team-name" :class="{'small':isMobile}">
                                {{ match.teams[selectedEvent.team].abbr }}
                            </h2>
                            <p class="match-situation-position" :class="{'small':isMobile}">
                                GOL!
                            </p>
                        </div>
                    </div>
                    <div v-else-if="selectedEvent.type === 'penalty_rewarded'" class="center-text-container">
                        <div class="center-text">
                            <v-img src="@/assets/lmt/goal.svg" class="match-situation-icon" contain/>
                            <h2 class="match-situation-team-name" :class="{'small':isMobile}">
                                {{ match.teams[selectedEvent.team].abbr }}
                            </h2>
                            <p class="match-situation-position" :class="{'small':isMobile}">
                                Penaltı
                            </p>
                        </div>
                    </div>
                    <div v-else-if="selectedEvent.type === 'freekick'" class="center-text-container">
                        <div class="center-text">
                            <h2 class="match-situation-team-name" :class="{'small':isMobile}">
                                {{ match.teams[selectedEvent.team].abbr }}
                            </h2>
                            <p class="match-situation-position" :class="{'small':isMobile}">
                                Serbest Vuruş
                            </p>
                        </div>
                    </div>
                    <div v-else-if="selectedEvent.type === 'offside'" class="center-text-container">
                        <div class="center-text">
                            <v-img src="@/assets/lmt/offsideFlag.svg" class="match-situation-icon" contain/>
                            <h2 class="match-situation-team-name" :class="{'small':isMobile}">
                                {{ match.teams[selectedEvent.team].abbr }}
                            </h2>
                            <p class="match-situation-position" :class="{'small':isMobile}">
                                Ofsayt
                            </p>
                        </div>
                    </div>
                    <div v-else-if="selectedEvent.type === 'card'" class="center-text-container">
                        <div class="center-text">
                            <v-img v-if="selectedEvent.card === 'yellow'" src="@/assets/lmt/yellowCard.svg"
                                   class="match-situation-icon" contain/>
                            <v-img v-else src="@/assets/lmt/redCard.svg" class="match-situation-icon" contain/>
                            <h2 class="match-situation-team-name" :class="{'small':isMobile}">
                                {{ match.teams[selectedEvent.team].abbr }}
                            </h2>
                            <p class="match-situation-position" :class="{'small':isMobile}">
                                {{ selectedEvent.name }}
                            </p>
                        </div>
                    </div>
                    <div v-else-if="selectedEvent.type === 'throwin'" class="center-text-container">
                        <div class="center-text">
                            <h2 class="match-situation-team-name" :class="{'small':isMobile}">
                                {{ match.teams[selectedEvent.team].abbr }}
                            </h2>
                            <p class="match-situation-position" :class="{'small':isMobile}">
                                Taç
                            </p>
                        </div>
                    </div>
                    <div v-else-if="selectedEvent.type === 'goal_kick'" class="center-text-container">
                        <div class="center-text">
                            <h2 class="match-situation-team-name" :class="{'small':isMobile}">
                                {{ match.teams[selectedEvent.team].abbr }}
                            </h2>
                            <p class="match-situation-position" :class="{'small':isMobile}">
                                Kale Vuruşu
                            </p>
                        </div>
                    </div>
                    <div v-else-if="selectedEvent.type === 'shotontarget'" class="center-text-container">
                        <div class="center-text">
                            <v-img src="@/assets/lmt/shotOnTarget.svg" class="match-situation-icon" contain/>
                            <h2 class="match-situation-team-name" :class="{'small':isMobile}">
                                {{ match.teams[selectedEvent.team].abbr }}
                            </h2>
                            <p class="match-situation-position" :class="{'small':isMobile}">
                                İsabetli Şut
                            </p>
                        </div>
                    </div>
                    <div v-else-if="selectedEvent.type === 'shotofftarget'" class="center-text-container">
                        <div class="center-text">
                            <v-img src="@/assets/lmt/shotOffTarget.svg" class="match-situation-icon" contain/>
                            <h2 class="match-situation-team-name" :class="{'small':isMobile}">
                                {{ match.teams[selectedEvent.team].abbr }}
                            </h2>
                            <p class="match-situation-position" :class="{'small':isMobile}">
                                İsabetsiz Şut
                            </p>
                        </div>
                    </div>
                    <div v-else-if="selectedEvent.type === 'injury'" class="center-text-container">
                        <div class="center-text">
                            <h2 class="match-situation-team-name" :class="{'small':isMobile}">
                                {{ match.teams[selectedEvent.team].abbr }}
                            </h2>
                            <p class="match-situation-position" :class="{'small':isMobile}">
                                Sakatlık
                            </p>
                        </div>
                    </div>
                    <div v-else-if="selectedEvent.type === 'corner'" class="center-text-container">
                        <div class="center-text">
                            <v-img src="@/assets/lmt/corner.svg" class="match-situation-icon mb-1" contain/>
                            <h2 class="match-situation-team-name" :class="{'small':isMobile}">
                                {{ match.teams[selectedEvent.team].abbr }}
                            </h2>
                            <p class="match-situation-position" :class="{'small':isMobile}">
                                Korner
                            </p>
                        </div>
                    </div>
                </template>
                <!--match status -- if not live-->
                <template v-else>
                    <div class="center-text-container">
                        <div class="center-text">
                            <h2 class="match-situation-team-name" :class="{'small':isMobile}">
                                {{ matchStatus }}
                            </h2>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios';
import {getPathD} from "@/utils/segment_calculator";
/*STATUS MAP
*data.data.doc[0].data.match.status._id === 0 => not started
*data.data.doc[0].data.match.status._id === 7 => 2nd half
*data.data.doc[0].data.match.status._id === 100 => ended
*data.data.doc[0].data.match.status._id === 31 => first half
*data.data.doc[0].data.match.status._id === 20 => started
* */
export default {
    name: "FvLmt",
    props: {
        matchId: {
            type: Number,
            required: false
        },
        withScoreboard: {
            type: Boolean,
            default: false,
            required: false
        }
    },
    data() {
        return {
            events: [],
            allEvents: [],
            live: false,
            match: null,
            selectedEvent: null,
            interval: null,
            seconds: 0,
            matchStatus: '',
            matchTime: 0,
            specialEvents: [],
            MATCH_SITUATION,
            isMobile: false,
        }
    },
    mounted() {
        this.fetchEvents();
        this.interval = setInterval(() => {
            this.fetchEvents();
        }, 4000);
        document.getElementById(`lmt_${this.matchId}`).addEventListener('resize', this.handleResize);
        this.handleResize();
    },
    methods: {
        async fetchEvents() {
            const data = await axios.get(`https://lmt.fn.sportradar.com/common/tr/Etc:UTC/gismo/match_timelinedelta/${this.matchId || this.$route.params.id}`)
            /*check logo exists*/
            this.checkLogo();
            if (data.data.doc[0].data.match) {
                this.match = data.data.doc[0].data.match;
            }
            let events = data.data.doc[0].data.events;
            switch (data.data.doc[0].data.match.status._id) {
                case 0:
                    this.matchStatus = 'Maç Başlamadı';
                    this.selectedEvent = null;
                    this.live = false;
                    break
                case 31:
                    this.matchStatus = 'Devre Arası';
                    this.selectedEvent = null;
                    this.live = false;
                    break
                case 70:
                    this.matchStatus = 'Maç İptal';
                    this.selectedEvent = null;
                    this.live = false;
                    clearInterval(this.interval);
                    break
                case 100:
                    this.matchStatus = 'Maç Sona Erdi';
                    this.selectedEvent = null;
                    this.live = false;
                    clearInterval(this.interval);
                    break
                default:
                    this.live = true;
                    events.forEach(e => {
                        if (e.time > this.matchTime)
                            this.matchTime = e.time;
                    })
            }
            this.allEvents = events;
            if (events.length > 0) {
                this.events = events.filter(e => e.seconds !== -1 && (
                    e.type === 'goal' ||
                    e.type === 'freekick' ||
                    e.type === 'offside' ||
                    e.type === 'shotontarget' ||
                    e.type === 'shotofftarget' ||
                    e.type === 'goal_kick' ||
                    e.type === 'throwin' ||
                    e.type === 'matchsituation' ||
                    e.type === 'corner' ||
                    e.type === 'penalty_rewarded' ||
                    e.type === 'card' ||
                    e.type === 'injury'
                ));
                let event = null;
                /*goal*/
                if (this.events.filter(e => e.type === 'goal').length > 0) {
                    event = this.events.filter(e => e.type === 'goal')[0];
                    this.selectedEvent = event;
                }
                /*penalty*/
                else if (
                    this.events.filter(e => e.type === 'penalty_rewarded').length > 0 &&
                    !this.specialEvents.includes(this.events.filter(e => e.type === 'penalty_rewarded')[0]._id)
                ) {
                    event = this.events.filter(e => e.type === 'penalty_rewarded')[0];
                    this.handleSpecialEventId(event);
                    this.selectedEvent = event;
                }
                /*free kick*/
                else if (
                    this.events.filter(e => e.type === 'freekick').length > 0 &&
                    !this.specialEvents.includes(this.events.filter(e => e.type === 'freekick')[0]._id)
                ) {
                    event = this.events.filter(e => e.type === 'freekick')[0];
                    this.handleSpecialEventId(event);
                    this.selectedEvent = event;
                }
                /*card*/
                else if (this.events.filter(e => e.type === 'card').length > 0
                    &&
                    !this.specialEvents.includes(this.events.filter(e => e.type === 'card')[0]._id)
                ) {
                    event = this.events.filter(e => e.type === 'card')[0];
                    this.handleSpecialEventId(event);
                    this.selectedEvent = event;
                }
                /*corner*/
                else if (this.events.filter(e => e.type === 'corner').length > 0
                    &&
                    !this.specialEvents.includes(this.events.filter(e => e.type === 'corner')[0]._id)
                ) {
                    event = this.events.filter(e => e.type === 'corner')[0];
                    this.handleSpecialEventId(event);
                    this.selectedEvent = event;
                }
                /*offside*/
                else if (this.events.filter(e => e.type === 'offside').length > 0
                    &&
                    !this.specialEvents.includes(this.events.filter(e => e.type === 'offside')[0]._id)
                ) {
                    event = this.events.filter(e => e.type === 'offside')[0];
                    this.handleSpecialEventId(event);
                    this.selectedEvent = event;
                }
                /*shot on target*/
                else if (this.events.filter(e => e.type === 'shotontarget').length > 0
                    &&
                    !this.specialEvents.includes(this.events.filter(e => e.type === 'shotontarget')[0]._id)
                ) {
                    event = this.events.filter(e => e.type === 'shotontarget')[0];
                    this.handleSpecialEventId(event);
                    this.selectedEvent = event;
                }
                /*shot off target*/
                else if (this.events.filter(e => e.type === 'shotofftarget').length > 0
                    &&
                    !this.specialEvents.includes(this.events.filter(e => e.type === 'shotofftarget')[0]._id)
                ) {
                    event = this.events.filter(e => e.type === 'shotofftarget')[0];
                    this.handleSpecialEventId(event);
                    this.selectedEvent = event;
                }
                /*goal kick*/
                else if (this.events.filter(e => e.type === 'goal_kick').length > 0
                    &&
                    !this.specialEvents.includes(this.events.filter(e => e.type === 'goal_kick')[0]._id)
                ) {
                    event = this.events.filter(e => e.type === 'goal_kick')[0];
                    this.handleSpecialEventId(event);
                    this.selectedEvent = event;
                }
                /*throw in*/
                else if (this.events.filter(e => e.type === 'throwin').length > 0
                    &&
                    !this.specialEvents.includes(this.events.filter(e => e.type === 'throwin')[0]._id)
                ) {
                    event = this.events.filter(e => e.type === 'throwin')[0];
                    this.handleSpecialEventId(event);
                    this.selectedEvent = event;
                }

                else if (this.events.filter(e => e.type === 'throwin').length > 0
                    &&
                    !this.specialEvents.includes(this.events.filter(e => e.type === 'throwin')[0]._id)
                ) {
                  event = this.events.filter(e => e.type === 'throwin')[0];
                  this.handleSpecialEventId(event);
                  this.selectedEvent = event;
                }
                /*injury*/
                else if (this.events.filter(e => e.type === 'injury').length > 0
                    &&
                    !this.specialEvents.includes(this.events.filter(e => e.type === 'injury')[0]._id)
                ) {
                    event = this.events.filter(e => e.type === 'injury')[0];
                    this.handleSpecialEventId(event);
                    this.selectedEvent = event;
                }
                    /*todo: substitution
                    else if (this.events.filter(e => e.type === 'substitution').length > 0) {

                        this.handleSpecialEventId(event);
                        event = this.events.filter(e => e.type === 'substitution')[0];
                        this.selectedEvent = event;
                    }*/
                /*match situation -- try as default*/
                else if (this.events.filter(e => e.type === 'matchsituation').length > 0) {
                    event = this.events.filter(e => e.type === 'matchsituation')[0];
                    this.selectedEvent = event;
                }
            }
        },

        handleSpecialEventId(event) {
            this.specialEvents.push(event._id);
        },

        checkLogo() {
            if (!document.getElementById('lmt_logo')) {
                clearInterval(this.interval);
            }
        },
        handleResize() {
            this.isMobile = document.getElementById(`lmt_${this.matchId}`).style.width < 860;
        },
    },

    beforeDestroy() {
        clearInterval(this.interval);
    },

    computed: {
        matchSituation() {
            if (this.selectedEvent && this.selectedEvent.type === 'matchsituation') {
                if (this.selectedEvent.situation === 'safe') {
                    return 'lmt-match-situation-safe'
                } else if (this.selectedEvent.situation === 'attack') {
                    return 'lmt-match-situation-attack'
                } else if (this.selectedEvent.situation === 'dangerous') {
                    return 'lmt-match-situation-dangerous'
                } else {
                    return ''
                }
            } else {
                return ''
            }
        },
        hasScoreboard() {
            return !(
                this.$route.query.scoreboard === 'false' ||
                !this.withScoreboard ||
                !this.match
            );
        },
        ballPosition() {
            if (this.selectedEvent) {
                if (
                    this.selectedEvent.type === 'freekick' ||
                    this.selectedEvent.type === 'shotontarget' ||
                    this.selectedEvent.type === 'shotofftarget' ||
                    this.selectedEvent.type === 'goal_kick' ||
                    this.selectedEvent.type === 'corner' ||
                    this.selectedEvent.type === 'throwin'
                ) {
                    if (this.selectedEvent.X && this.selectedEvent.Y) {
                        let fixedX;
                        let fixedY;
                        if (this.selectedEvent.X < 7) {
                            fixedX = 2;
                        } else if (this.selectedEvent.X > 93) {
                            fixedX = 398;
                        } else {
                            fixedX = this.selectedEvent.X * 4;
                        }
                        if (this.selectedEvent.Y < 7) {
                            fixedY = 2;
                        } else if (this.selectedEvent.Y > 93) {
                            fixedY = 178;
                        } else {
                            fixedY = Math.floor(this.selectedEvent.Y * 1.8)
                        }
                        return {show: true, x: fixedX, y: fixedY};
                    } else
                        return {show: false}
                } else {
                    return {show: false}
                }
            } else {
                return {show: false}
            }
        },

        segmentPosition() {
            if (this.selectedEvent && this.ballPosition.show) {
                const basePosition = this.selectedEvent.team === 'home' ? {x: 400, y: 90} : {x: 0, y: 90};
                return getPathD(basePosition.x, basePosition.y, this.ballPosition.x, this.ballPosition.y, 60);
            } else {
                return null;
            }
        }
    },

    watch: {
        specialEvents() {
            if (this.specialEvents.length > 5) {
                this.specialEvents.shift();
            }
        }
    }
}

const MATCH_SITUATION = {
    attack: 'Atak',
    safe: 'Top Güvenli Yerde',
    dangerous: 'Tehlikeli Atak'
}
</script>

<style scoped>
.lmt {
    width: 100%;
    height: auto;
    background-color: #383838;
    color: #ffffff;
}

.debug-container {
    text-align: center;
    margin: 10px auto;
}

.lmt-container {
    position: relative;
    margin-bottom: -5px;
}

.center-text-container {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: calc(100% - 5px);
    background-color: rgba(0, 0, 0, 0.31);
}

.center-text {
    display: grid;
    width: 100%;
    height: 100%;
    align-content: center;
    align-items: center;
    text-align: center;
    color: white;
}

.match-status {
    position: absolute;
    opacity: 1;
    display: block;
    text-align: center;
    top: 44%;
    width: 100%;
    color: white;
}

.lmt-scoreboard {
    width: 100%;
    height: 40px;
    line-height: 40px;
    margin-bottom: 5px;
    padding: 0 15px;
    display: grid;
    background-color: #383838;
    grid-template-columns: minmax(40%, calc(50% - 20px)) minmax(20px, 10%) 10px minmax(20px, 10%) minmax(40%, calc(50% - 20px));
}

.lmt-scoreboard-home {
    text-align: right;
}

.lmt-scoreboard-score-home, .lmt-scoreboard-score-away {
    text-align: center;
    font-weight: bold;
}

.lmt-scoreboard-home, .lmt-scoreboard-away {
    padding: 0 10px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.lmt-scoreboard-score-home {
    border-bottom: 4px solid white;
    z-index: 9;
    height: 40px;
    color: #ffdf1b;
}

.lmt-scoreboard-score-away {
    border-bottom: 4px solid red;
    z-index: 9;
    height: 40px;
    color: #ffdf1b;
}

.match-time-container {
    position: absolute;
    height: 2rem;
    width: 100%;
    text-align: center;
    padding-top: .3rem;
    z-index: 9;
}

.match-time {
    color: #12e096;
    background-color: #00000066;
    padding: 5px 1rem 3px 1rem;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    z-index: 9;
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.4);
}

.lmt-match-situation-container {
    display: flex;
    position: relative;
    align-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.lmt-match-situation-home {
    position: absolute;
    width: 50%;
    display: none;
    top: 0;
    height: calc(100% - 5px);
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.25) 0%, rgba(0, 0, 0, 0.4) 100%);
    transition: .5s;
}

.lmt-match-situation-away {
    position: absolute;
    width: 50%;
    display: none;
    top: 0;
    height: calc(100% - 5px);
    background: linear-gradient(90deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.25) 100%);
    transition: .5s;
    right: 0;
}

.logo-container {
    position: absolute;
    bottom: 1rem;
    left: 0;
    width: 100%;
    text-align: center;
}

.lmt-logo {
    margin: 0 auto;
    max-width: 741px;
    max-height: 78px;
    width: 50%;
    opacity: .2;
}

.lmt-match-situation-safe {
    display: block;
    width: 50%;
}

.lmt-match-situation-attack {
    display: block;
    width: 70%;
}

.lmt-match-situation-dangerous {
    display: block;
    width: 85%;
}

.lmt-match-situation-text-box-home {
    padding: 0 10px;
    border-right: 3px solid white;
    display: inline-block;
    text-align: center;
    margin-left: auto;
    margin-right: 10px;
}

.match-situation-position {
    margin-bottom: 0;
}

.lmt-match-situation-text-box-away {
    padding: 0 10px;
    border-left: 3px solid red;
    display: inline-block;
    text-align: center;
    margin-left: 10px;
}

.match-time.small {
    font-size: 1rem;
}

.match-situation-team-name {
    color: #12e096;
}

.match-situation-team-name.small {
    font-size: 1.1rem;
}

.match-situation-position.small {
    font-size: .9rem;
}

.lmt-scoreboard.small {
    font-size: 1rem;
}

@keyframes blink {
    0% {
        fill: #00000044;
    }
    25% {
        fill: #00000022;
    }
    50% {
        fill: #00000000;
    }
    75% {
        fill: #00000022;
    }
    100% {
        fill: #00000044;
    }
}

@-webkit-keyframes blink {
    0% {
        color: red;
    }
    100% {
        color: black;
    }
}

.blink-animation {
    -webkit-animation: blink 1s linear infinite;
    -moz-animation: blink 1s linear infinite;
    animation: blink 2s linear infinite;
}

@media only screen and (max-width: 860px) {
    .match-time {
        font-size: 1rem;
    }

    .match-situation-team-name {
        font-size: 1.3rem;
    }

    .match-situation-position {
        font-size: 1rem;
    }

    .lmt-scoreboard {
        font-size: 1rem;
    }

    .match-situation-icon {
        width: 2rem;
        max-width: 2rem;
        height: 2rem;
        max-height: 2rem;
        margin-left: auto;
        margin-right: auto;
    }
}

@media only screen and (min-width: 860px) {
    .match-time {
        font-size: 2rem;
    }

    .match-situation-team-name {
        font-size: 1.8rem;
        margin: .5rem;
    }

    .match-situation-position {
        font-size: 1.3rem;
    }

    .lmt-scoreboard {
        font-size: 1.3rem;
    }

    .match-situation-icon {
        width: 3rem;
        max-width: 3rem;
        height: 3rem;
        max-height: 3rem;
        margin-left: auto;
        margin-right: auto;
    }
}
</style>
